@if (modalContent) {
<avv-modal [type]="modalContent.type" [hidden]="hideModal">
  <div class="modal-container">
    <img class="o-modal-icon" [src]="modalContent.icon | asset" alt="Modal Icon">
    <span class="modal-container__title">{{ modalContent.title }}</span>
    <p class="modal-container__text" [innerHTML]="modalContent.text"></p>
  </div>
  <button avv-dialog-btn-continue class="avv-btn-secondary" (click)="modalRedirect()" type="button">{{
    modalContent.button }}</button>
</avv-modal>
}