import { ApplicationConfig } from "@angular/core";
import { provideRouter, withPreloading } from "@angular/router";

import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { getSingleSpaExtraProviders } from "single-spa-angular";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { loaderInterceptor } from "./interceptors/loader.interceptor";
import { headerInterceptor } from "./interceptors/header-interceptor.interceptor";
import { APP_BASE_HREF } from "@angular/common";
import { BasePath } from "./shared/enums/routes.enum";
import { CustomPreloadingStrategy } from "./shared/utils/custom-preloading.strategy";

export const appConfig: ApplicationConfig = {
  providers: [
    getSingleSpaExtraProviders(),
    provideRouter(routes, withPreloading(CustomPreloadingStrategy)),
    provideClientHydration(),
    provideHttpClient(withInterceptors([loaderInterceptor, headerInterceptor])),
    {
      provide: APP_BASE_HREF,
      useValue: BasePath.Base,
    },
  ],
};
