import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./components/open-cdt/open-cdt.routes").then(
        (m) => m.openCdtRoutes,
      ),
  },
  {
    path: "otp",
    loadChildren: () =>
      import("./components/otp/otp.routes").then((m) => m.otpRoutes),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: "seleccion-cuentas",
    loadChildren: () =>
      import("./components/accounts/accounts.routes").then(
        (m) => m.accountsRoutes,
      ),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: "configuracion",
    loadChildren: () =>
      import("./components/cdt-configuration/cdt-configuration.routes").then(
        (m) => m.cdtConfigurationRoutes,
      ),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: "tips",
    loadChildren: () =>
      import("./components/tips/tips.routes").then((m) => m.tipsRoutes),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: "resumen-producto",
    loadChildren: () =>
      import("./components/summary/summary.routes").then(
        (m) => m.summaryRoutes,
      ),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: "desborde",
    loadChildren: () =>
      import(
        "./shared/components/generic-overflow/generic-overflow.routes"
      ).then((m) => m.genericOverflowRoutes),
    data: { preload: true },
  },
  { path: "**", component: EmptyRouteComponent },
];
