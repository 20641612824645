import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TealiumUtagService } from "./tealium/tealium-utag-service.service";
import { environment } from "../environments/environment";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from "@angular/router";
import { filter, map } from "rxjs/operators";
import { FormControl, Validators } from "@angular/forms";
import { StoreCdtService } from "./shared/services/store-cdt.service";
import { EventBusService } from "./shared/services/event-bus.service";
import { Subscription } from "rxjs";
import { TransactionIdService } from "./shared/services/transaction-id.service";
import { UserDataService } from "./shared/services/user-data.service";
import { UserInformation, CustomerData } from "./shared/models/basic-data";
import { AccountInformation } from "./shared/models/account-data";
import { ChannelsTopicEnum } from "./shared/enums/channels-topics.enum";
import { ModalErrorTypes } from "./shared/enums/error-types.enum";
import {
  ProductName,
  ProductResult,
  Topic,
} from "./shared/enums/channel-notification.enum";
import { BasePath } from "./shared/enums/routes.enum";
import { LinkEvent } from "./shared/models/tealium-events";
import { HttpClientModule } from "@angular/common/http";
import { AsyncPipe } from "@angular/common";
import { LoadingService } from "./shared/services/loading.service";
import { GenericModalComponent } from "./shared/components/generic-modal/generic-modal.component";

@Component({
  providers: [TealiumUtagService],
  selector: "app-cdt-integration",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [RouterOutlet, HttpClientModule, AsyncPipe, GenericModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit, OnDestroy {
  private tealiumUtagService = inject(TealiumUtagService);
  private router = inject(Router);
  private storeCdtService = inject(StoreCdtService);
  private eventBusService = inject(EventBusService);
  private userDataService = inject(UserDataService);
  private transactionIdService = inject(TransactionIdService);
  private loadingService = inject(LoadingService);
  private cdr = inject(ChangeDetectorRef);

  title = "bavv-pasivo-frontend-cdt-integration-mf";
  public searchControl: FormControl = new FormControl(null, [
    Validators.required,
  ]);
  public subscriptions = new Subscription();
  hideOverflowModal: boolean;
  modalErrType: string;
  currentUrl: string;
  previousUrl: string;
  isLoading = false;

  constructor() {
    this.hideOverflowModal = true;
    this.modalErrType = "";
    this.currentUrl = "";
    this.previousUrl = "";
    this.getTemplateText();
    this.getTemplateConstants();
    this.tealiumConfig();
  }

  ngOnInit(): void {
    this.startChannelsListener();
    this.loadingStatus();
    this.suscribeToRouteEvents();
    this.validateHistoryState();
    this.validateNavigationChanges();
    this.addPopstateListener();
    this.getOverflows();
  }

  /**
   * Método que se suscribe para obtener la información del usuario y cuentas
   */
  startChannelsListener(): void {
    let subCustomerInformation = this.eventBusService
      .startListener<CustomerData>(ChannelsTopicEnum.basicCustomerInformation)
      .subscribe({
        next: (data) => {
          this.saveCustomerData(data?.value);
        },
        error: () => {
          this.sendNotification("No user data");
          this.triggerModalError();
        },
      });
    let subCustomerAccounts = this.eventBusService
      .startListener<AccountInformation>(
        ChannelsTopicEnum.basicCustomerAccounts,
      )
      .pipe(
        map((data: any) => {
          return data.value;
        }),
      )
      .subscribe({
        next: (accounts: AccountInformation[]) => {
          this.saveAccountData(accounts);
        },
        error: () => {
          this.sendNotification("No account data");
          this.triggerModalError();
        },
      });
    this.subscriptions.add(subCustomerInformation);
    this.subscriptions.add(subCustomerAccounts);
  }

  /**
   * Método para hacer la configuración de tealium
   */
  tealiumConfig(): void {
    this.tealiumUtagService.setConfig({
      account: environment.account,
      profile: environment.profile,
      environment: environment.ambiente,
      sync: false,
    });

    this.tealiumUtagService.setConfig({
      account: environment.account,
      profile: environment.profile,
      environment: environment.ambiente,
      sync: true,
    });

    this.tealiumUtagService.createTagSync();
  }

  /**
   * Método que escucha los eventos de navegación y los envía para la analítica
   */
  suscribeToRouteEvents(): void {
    this.router.events
      .pipe(
        filter(
          (event: any): event is NavigationEnd =>
            event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this.observableAnalitics(event);
      });
  }

  /**
   * Método que valida si hay un cambio en la navegación externa de la aplicación
   */
  validateHistoryState(): void {
    window.onpopstate = (e) => {
      if ("singleSpaTrigger" in e) {
        return;
      } else {
        history.replaceState(null, "", "/bancadigital");
      }
    };
  }

  /**
   * Método que envía el evento view de analítica
   * @param event url en la que se encuentra el usuario
   */
  observableAnalitics(event: NavigationEnd): void {
    this.tealiumUtagService.track("view", {
      event: "view",
      Producto: "Flujos cortos cdt",
      Page: `${BasePath.Base}${event.url}`,
    });
  }

  /**
   * Método que obtiene los textos del template del repositorio de assets y lo guarda en una variable de sesión
   */
  getTemplateText(): void {
    this.storeCdtService.getTemplateCdt().subscribe((templateJson) => {
      sessionStorage.setItem("templateJson", JSON.stringify(templateJson));
    });
  }

  /**
   * Método que obtiene las constantes de la aplicación del repositorio de assets y lo guarda en una variable de sesión
   */
  getTemplateConstants(): void {
    this.storeCdtService.getConstantsCdt().subscribe((constantsJson) => {
      const encodedConstantsJson = btoa(JSON.stringify(constantsJson));
      sessionStorage.setItem("constantsJson", encodedConstantsJson);
    });
  }

  /**
   * Método que obtiene las constantes de todos los desordes de la aplicacion
   */
  getOverflows(): void {
    this.storeCdtService.getOverflows().subscribe((response) => {
      this.storeCdtService.overflows.next(response);
    });
  }

  /**
   * Método que muestra la modal de error técnico
   */
  triggerModalError(): void {
    setTimeout(() => {
      this.modalErrType = ModalErrorTypes.TechnicalError;
      this.hideOverflowModal = false;
    }, 1000);
  }

  /**
   * Método que publica un evento indicando que hubo un error en el flujo
   */
  sendNotification(description: string): void {
    this.eventBusService.publishEvent(
      Topic.Summary,
      {
        productName: ProductName.Name,
        productResult: ProductResult.Error,
        location: window.location.pathname,
        description: `Technical error: ${description}`,
      },
      false,
    );
  }

  /**
   * Método que almacena la información del usuario proveniente del bus de eventos
   * @param customerData objeto con la información del usuario
   */
  saveCustomerData(customerData: UserInformation): void {
    if (customerData) {
      this.userDataService.customerData.userInformation = customerData;
      this.transactionIdService.generateTransactionId(
        customerData.documentNumber,
      );
    } else {
      this.sendNotification("No user data");
      this.triggerModalError();
    }
  }

  /**
   * Método que almacena la información de las cuentas del usuario proveniente del bus de eventos
   * @param accounData objeto con la información de las cuentas
   */
  saveAccountData(accounData: AccountInformation[]): void {
    if (accounData && accounData.length > 0) {
      this.userDataService.customerData.accountInformation = accounData;
    } else {
      this.sendNotification("No account data");
      this.triggerModalError();
    }
  }

  /**
   * Método que detecta cuando se está haciendo una redirección y almacena las URLs
   */
  validateNavigationChanges(): void {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter(
          (event: any): event is NavigationStart =>
            event instanceof NavigationStart,
        ),
      )
      .subscribe((event) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  /**
   * Método que detecta cuando hay un cambio en la URL
   */
  addPopstateListener(): void {
    window.addEventListener("popstate", () => {
      if (!window.location.pathname.startsWith(BasePath.Base)) {
        const pathName = this.currentUrl?.includes("/../")
          ? this.previousUrl
          : this.currentUrl;
        this.sendTealiumEvents(pathName);
      }
    });
  }

  /**
   * Método que envía un evento a tealium notificando que hubo un abandono por parte del cliente
   * @param pathName ruta en la cual estaba el usuario cuando sucedió el abandono
   */
  sendTealiumEvents(pathName: string): void {
    const event: LinkEvent = {
      event_category: environment.channel,
      event_action: `${BasePath.Base}${pathName}`,
      event_label: "user-gone",
      tealium_event: "user-gone",
      transactionId: this.userDataService.customerData.transactionId,
    };
    this.tealiumUtagService.link(event);
  }

  /**
   * Método que valida el estado del loader
   * @returns el valor boolean del estado
   */
  loadingStatus(): void {
    this.subscriptions.add(
      this.loadingService.getStatusLoader().subscribe((isLoading) => {
        this.isLoading = isLoading;
        this.cdr.detectChanges();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
