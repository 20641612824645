import { Injectable, NgZone } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private ngZone: NgZone) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.ngZone.run(() => {
      return route.data?.["preload"] ? load() : of(null);
    });
  }
}
