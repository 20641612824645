import { inject } from "@angular/core";
import { HttpInterceptorFn } from "@angular/common/http";
import { LoadingService } from "../shared/services/loading.service";
import { finalize } from "rxjs/operators";
import { ExcludedLoaderPaths } from "./interceptor-constants/excluded-paths";

export const loaderInterceptor: HttpInterceptorFn = (request, next) => {
  const loadingService = inject(LoadingService);

  let activeRequests: number = 0;
  const excludedPaths: string[] = ExcludedLoaderPaths;
  const isExcluded = excludedPaths.some((path) => request.url.includes(path));

  if (!isExcluded) {
    const requestLoadingParam = request.params.get("loading-component");
    if (requestLoadingParam !== "false") {
      if (activeRequests === 0) {
        loadingService.startLoader();
      }
      activeRequests++;

      return next(request).pipe(
        finalize(() => {
          activeRequests--;
          if (activeRequests === 0) {
            loadingService.stopLoader();
          }
        }),
      );
    } else {
      return next(request);
    }
  } else {
    return next(request);
  }
};
