import { inject } from "@angular/core";
import { HttpInterceptorFn } from "@angular/common/http";
import { ExcludedHeaderPaths } from "./interceptor-constants/excluded-paths";
import { UserDataService } from "../shared/services/user-data.service";

export const headerInterceptor: HttpInterceptorFn = (request, next) => {
  const userDataService = inject(UserDataService);

  const excludedPaths: string[] = ExcludedHeaderPaths;
  const isExcluded = excludedPaths.some((path) => request.url.includes(path));
  if (isExcluded) {
    return next(request);
  } else {
    const reqWithHeaders = request.clone({
      setHeaders: {
        "x-adl-document-type":
          userDataService.customerData.userInformation.documentType,
        "x-adl-document-number":
          userDataService.customerData.userInformation.documentNumber,
        "transaction-id": userDataService.customerData.transactionId,
        "x-adl-channel": "bavv-pasivo-cdt-masivo",
      },
    });

    return next(reqWithHeaders);
  }
};
