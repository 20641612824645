import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SimulationData } from "../models/simulation-data";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Overflows } from "../models/overflows-data";

@Injectable({
  providedIn: "root",
})
export class StoreCdtService {
  private http = inject(HttpClient);

  private simulationData = new BehaviorSubject<SimulationData>({});
  overflows = new BehaviorSubject<Overflows>({
    overflows: [],
    modalOverflows: [],
  });
  simulationData$ = this.simulationData.asObservable();

  /**
   * Emite los valores para ser almacenados y poder disponibilizarlos
   * @param detailData objeto con los datos del monto y el plazo para abrir el cdt
   */
  cdtDetail(detailData: SimulationData): void {
    this.simulationData.next(detailData);
  }

  /**
   * Realiza una petición para obtener el archivo JSON de los templates
   * @returns Retorna el objeto JSON con los textos de los templates obtenido desde los assets
   */
  getTemplateCdt(): Observable<any> {
    return this.http.get<any>(
      `${environment.urlAssets}/parameters/cdt-integration/template-texts.json`,
    );
  }

  /**
   * Realiza una petición para obtener el archivo JSON de las constantes
   * @returns Retorna el objeto JSON con las constantes de los validadores obtenido desde los assets
   */
  getConstantsCdt(): Observable<any> {
    return this.http.get<any>(
      `${environment.urlAssets}/parameters/cdt-integration/constants.json`,
    );
  }

  /**
   * Realiza una petición para obtener el archivo JSON de los desbordes
   * @returns Retorna el objeto JSON con los desbordes obtenidos desde los assets
   */
  getOverflows(): Observable<Overflows> {
    return this.http.get<Overflows>(
      `${environment.urlAssets}/parameters/cdt-integration/overflows.json`,
    );
  }
}
