import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private $loadingStatus = new BehaviorSubject<boolean>(false);
  public load: boolean = false;

  get loading(): boolean {
    return this.load;
  }

  getStatusLoader() {
    return this.$loadingStatus.asObservable();
  }

  startLoader() {
    this.$loadingStatus.next(true);
  }

  stopLoader() {
    document.body.classList.remove("loading");
    return this.$loadingStatus.next(false);
  }
}
