export enum Topic {
  Summary = "productSummary",
  Alive = "heartBeat",
}

export enum ProductName {
  Name = "CDT_CHANNELS",
}

export enum ProductResult {
  Success = "Success",
  Error = "Error",
}
