import { Injectable } from "@angular/core";
import { UserDataService } from "../../shared/services/user-data.service";

@Injectable({
  providedIn: "root",
})
export class TransactionIdService {
  calcRandom: number;
  random: number;
  transactionIdNumber: string;

  constructor(private userDataService: UserDataService) {
    this.calcRandom = 0;
    this.random = 0;
    this.transactionIdNumber = "";
  }

  /**
   * Método que genera un valor aleatorio
   * @returns valor aleatorio generado
   */
  getNewRandomInt(): number {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);

    if (array.length > 0) {
      this.calcRandom = array[0];
      if (this.calcRandom < 1000) {
        this.calcRandom *= 1000;
      }
      const length = Math.ceil(Math.log10(this.calcRandom + 1));
      if (length > 3) {
        this.calcRandom = this.calcRandom % 1000;
      }
      if (this.calcRandom < 100) {
        this.calcRandom += 100;
      }
      this.random = this.calcRandom;
    }
    return this.random;
  }

  /**
   * Método que genera el transaction id
   * @param id valor del número de documento del usuario
   */
  generateTransactionId(id: any): void {
    const randomValue = this.getNewRandomInt();
    const identifier = randomValue.toString() + (id - randomValue).toString();
    this.userDataService.customerData.transactionId = identifier;
  }
}
