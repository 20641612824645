import { Pipe, PipeTransform } from "@angular/core";
import { assetUrl } from "../../../single-spa/asset-url";

@Pipe({
  name: "asset",
  standalone: true,
})
export class SingleSpaAssetsPipe implements PipeTransform {
  transform(value: string): string {
    return assetUrl(value);
  }
}
