import { Injectable } from "@angular/core";
import { BasicData } from "../models/basic-data";
import { CdtOpening } from "../models/cdt-opening";
import { BehaviorSubject } from "rxjs";
import { SummaryCdtResponse } from "../../shared/models/services/summary-cdt-response";

@Injectable({
  providedIn: "root",
})
export class UserDataService {
  private basicData: BasicData;
  private cdtOpening: CdtOpening;

  private summaryData = new BehaviorSubject<SummaryCdtResponse | undefined>(
    undefined,
  );
  summaryData$ = this.summaryData.asObservable();

  constructor() {
    this.basicData = {} as BasicData;
    this.cdtOpening = {} as CdtOpening;
  }

  get customerData(): BasicData {
    return this.basicData;
  }

  set customerData(data: BasicData) {
    this.basicData = data;
  }

  get creationCdt(): CdtOpening {
    return this.cdtOpening;
  }

  set creationCdt(data: CdtOpening) {
    this.cdtOpening = data;
  }

  /**
   * Emite los valores para ser almacenados y poder disponibilizarlos
   * @param summaryData objeto con los datos del resumen del cdt
   */
  cdtSummary(summaryData: SummaryCdtResponse | undefined): void {
    if (summaryData) this.summaryData.next(summaryData);
  }
}
